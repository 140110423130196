import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CompanyPurchaseOrdersDetails.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  Catalogue,
  CatalogueItem,
  ErrorMessageBody,
  IncomingPOReportItemForSpreadsheetExport,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderAnalyticsObject,
  IncomingPurchaseOrderDataObject,
  IncomingPurchaseOrderItemDetails,
  IncomingPurchaseOrderItemDetailsHasError,
  IncomingPurchaseOrderItemDetailsHasErrorMessages,
  IncomingPurchaseOrderListingDetailsDataObject,
  Listing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  SkuValidationObject,
  SkuValidationResponseDataObject,
  SkuValidationResponseObject,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";

import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import CompanyPurchaseOrderPendingDispatchPDF from "./CompanyPurchaseOrderPendingDispatchPDF";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrdersDetails() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [
    currentPurchaseOrderListingDetails,
    setCurrentPurchaseOrderListingDetails,
  ] = useState<IncomingPurchaseOrderListingDetailsDataObject[]>([]);
  const [
    currentPurchaseOrderAnalyticsObject,
    setCurrentPurchaseOrderAnalyticsObject,
  ] = useState<IncomingPurchaseOrderAnalyticsObject>();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [acknowledgePurchaseOrder, setAcknowledgePurchaseOrder] =
    useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  const [workInProgress, setWorkInProgress] = useState(false);
  const [downloadDetailedReport, setDownloadDetailedReport] = useState(false);
  const [downloadPendencyPDF, setDownloadPendencyPDF] = useState(false);
  const [chunkedPurchaseOrderListingItem, setChunkedPurchaseOrderListingItem] =
    useState<IncomingPurchaseOrderListingDetailsDataObject[][]>([]);

  useEffect(() => {
    setChunkedPurchaseOrderListingItem(
      chunkArray(currentPurchaseOrderListingDetails, 30)
    );
  }, [currentPurchaseOrderListingDetails]);

  const chunkArray = (
    array: IncomingPurchaseOrderListingDetailsDataObject[],
    size: number
  ): IncomingPurchaseOrderListingDetailsDataObject[][] => {
    const sortedArray = [...array].sort((a, b) => {
      const diffA = a.demand - 0; //a.pending;
      const diffB = b.demand - 0; //b.pending;
      return diffB - diffA; // descending order
    });

    return Array.from(
      { length: Math.ceil(sortedArray.length / size) },
      (_, index) => sortedArray.slice(index * size, index * size + size)
    );
  };

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
    }
  };

  useEffect(() => {
    const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
            purchase_order_id.toString()
          );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderListingDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
            purchase_order_id.toString(),
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentPurchaseOrderListingDetails(response.data["content"]);

        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderAnalysisObject = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getPurchaseOrderAnalyticsObject(
            purchase_order_id.toString()
          );

        setCurrentPurchaseOrderAnalyticsObject(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPurchaseOrderDetails(po_id);
    fetchPurchaseOrderListingDetails(po_id);
    fetchPurchaseOrderAnalysisObject(po_id);
  }, []);

  const getPurchaseOrderListingDetailsPaginated = async (
    purchase_order_id: any,
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
          purchase_order_id.toString(),
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentPurchaseOrderListingDetails((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
    } catch (error) {
      toast.error("Error! Could not find purchase order details.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getPurchaseOrderListingDetailsPaginated(
      po_id,
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  const markForAcknowledgement = async () => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.acknowledgePurchaseOrder(
          po_id,
          userDetailsFromStore.user_details.userId
        );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Acknowledged successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    }
  };

  const markForWIP = async () => {
    setShowLoadingPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.markForWIP(
        po_id,
        userDetailsFromStore.user_details.userId
      );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Marked WIP successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setWorkInProgress(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setWorkInProgress(false);
    }
  };

  const downloadDetailedReportAsSpreadsheet = async () => {
    try {
      // itemsToExport.push()
      const transformedItems = currentPurchaseOrderListingDetails.map(
        (singleItem) => {
          return {
            po_sku: singleItem.po_sku,
            ecraft_ops_sku: singleItem.ecraft_ops_sku,
            master_sku: singleItem.master_sku,
            company_code_primary: singleItem.company_code_primary,
            company_code_secondary: singleItem.company_code_secondary,
            ops_tag: singleItem.listing.ops_tag,
            warehouse_quantity: singleItem.listing.available_quantity,
            demand: singleItem.demand,
            packed: 0,
            dispatched: 0,
            pending: 0,
            pending_percent: 0,
          };
        }
      );
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = currentPurchaseOrder
          ? currentPurchaseOrder.po_number + "_detailed_report.csv"
          : "po_detailed_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });

      setDownloadDetailedReport(false);
    } catch (error) {
      console.log(
        "Printing error from CompanyPurchaseOrderDetails -> Download detailed report.   "
      );
      console.error("Error:", error);

      setDownloadDetailedReport(false);
    }
  };

  return (
    <div className="upload-purchase-order-details-container">
      {currentPurchaseOrder && (
        <div className="incoming-purchase-order-details-table-container pt-4">
          <Table striped bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td
                  className={getCalculatedPoStatusColor(
                    currentPurchaseOrder.calculated_po_status
                  )}
                >
                  {currentPurchaseOrder.calculated_po_status}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Marked WIP ?</td>
                <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                  {currentPurchaseOrder.is_wip}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer Company Name</td>
                <td>{currentPurchaseOrder.company.company_name}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_number}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Reference Location</td>
                <td>{currentPurchaseOrder.delivery_city}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Delivery Address</td>
                <td>{currentPurchaseOrder.delivery_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Billing Address</td>
                <td>{currentPurchaseOrder.billing_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer GSTIN</td>
                <td>{currentPurchaseOrder.buyer_gstin}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Expiry Date</td>
                <td>{currentPurchaseOrder.expiry_date}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created by</td>
                <td>{currentPurchaseOrder.created_by}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created At</td>
                <td>{currentPurchaseOrder.created_at}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {currentPurchaseOrderAnalyticsObject && (
        <div className="incoming-purchase-order-details-table-container pt-4">
          <Table striped bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Number of SKUs</td>
                <td>{currentPurchaseOrderAnalyticsObject.sku_count}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Demand</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_demand}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Pending</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_pending}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Packed</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_packed}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Dispatched</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_dispatched}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Boxed Packed</td>
                <td>{currentPurchaseOrderAnalyticsObject.boxes_packed}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Boxes Dispatched</td>
                <td>{currentPurchaseOrderAnalyticsObject.boxes_dispatched}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {currentPurchaseOrder && (
        <div className="vendor-action-buttons-container incoming-purchase-order-details-table-container pt-4">
          {currentPurchaseOrder.calculated_po_status ===
          "ACKNOWLEDGEMENT PENDING" ? (
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setAcknowledgePurchaseOrder(true)}
            >
              Acknowledge Purchase Order
            </Button>
          ) : (
            <div>
              {currentPurchaseOrder.calculated_po_status !==
                "MARKED CANCELLED" && (
                <div>
                  <Button
                    variant="outline-primary"
                    className="vendor-action-button"
                    onClick={() => setCancelPurchaseOrder(true)}
                  >
                    Cancel Purchase Order
                  </Button>

                  {currentPurchaseOrder.is_wip === "NO" && (
                    <Button
                      variant="outline-primary"
                      className="vendor-action-button"
                      onClick={() => setWorkInProgress(true)}
                    >
                      Add this purchase order to WIP
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}

          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setDownloadDetailedReport(true)}
          >
            Download detailed report
          </Button>
          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setDownloadPendencyPDF(true)}
          >
            Download pendency pdf
          </Button>
        </div>
      )}

      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentPurchaseOrder && (
          <div className="po-items-container-details">
            <Table bordered hover size="sm">
              <thead className="sticky-header">
                <tr>
                  <th>sr_no.</th>
                  <th>image</th>
                  <th>po_sku</th>
                  <th>ecraft_ops_sku</th>
                  <th>master_sku</th>
                  <th>company_code_primary</th>
                  <th>warehouse_quantity</th>
                  <th>demand</th>
                  <th>packed</th>
                  <th>dispatched</th>
                  <th>pending</th>
                  <th>pending_percent</th>
                </tr>
              </thead>
              <tbody>
                {currentPurchaseOrderListingDetails.map(
                  (purchaseOrderItem, index) => {
                    const isExpanded = expandedRow === index;
                    return (
                      <React.Fragment key={index}>
                        <tr
                          className={
                            isExpanded
                              ? "incoming-po-search-bar-row expanded-main-row"
                              : "incoming-po-search-bar-row"
                          }
                          onClick={() => toggleRow(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <img
                              style={{ height: "70px", width: "70px" }}
                              src={purchaseOrderItem.listing.img_wdim}
                              alt="Product Image"
                            />
                          </td>
                          <td>{purchaseOrderItem.po_sku}</td>
                          <td>{purchaseOrderItem.ecraft_ops_sku}</td>
                          <td>{purchaseOrderItem.master_sku}</td>
                          <td>{purchaseOrderItem.company_code_primary}</td>
                          <td>
                            {purchaseOrderItem.listing.available_quantity}
                          </td>
                          <td>{purchaseOrderItem.demand}</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        {isExpanded && (
                          <tr
                            className={`incoming-po-search-bar-row expandable-content-po ${
                              isExpanded ? "expanded" : "collapsed"
                            }`}
                          >
                            <td></td>
                            <td colSpan={11} className="po-expanded-cell">
                              <div className="expanded-content">
                                <div className="expanded-item">
                                  <strong>Title:</strong>{" "}
                                  {purchaseOrderItem.title}
                                </div>
                                <div className="expanded-item">
                                  <strong>Company Code Secondary:</strong>{" "}
                                  {purchaseOrderItem.company_code_secondary}
                                </div>
                                <div className="expanded-item">
                                  <strong>HSN:</strong>{" "}
                                  {purchaseOrderItem.hsn_code}
                                </div>
                                <div className="expanded-item">
                                  <strong>MRP:</strong> {purchaseOrderItem.mrp}
                                </div>
                                <div className="expanded-item">
                                  <strong>NLC/TP:</strong>{" "}
                                  {purchaseOrderItem.nlc_tp}
                                </div>
                                <div className="expanded-item">
                                  <strong>Size:</strong>{" "}
                                  {purchaseOrderItem.size}
                                </div>
                                <div className="expanded-item">
                                  <strong>Color:</strong>{" "}
                                  {purchaseOrderItem.color}
                                </div>
                                <p className="expanded-additional">
                                  <em>Additional details for:</em>{" "}
                                  {purchaseOrderItem.po_sku}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </Table>
            {searchParams.item_count > 0 &&
              searchParams.item_count < searchParams.expected_item_count && (
                <div className="load-more-catalogues-button-container">
                  <Button
                    variant="outline-primary"
                    className="load-more-catalogues-button"
                    onClick={() => loadMore()}
                  >
                    Load More..
                  </Button>
                </div>
              )}
          </div>
        )
      )}

      <Modal
        show={acknowledgePurchaseOrder}
        onHide={() => setAcknowledgePurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acknowledge Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to acknowledge this Purchase Order ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setAcknowledgePurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForAcknowledgement()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order ? Please note that
          if the pack task is open or the PO is in WIP state, it cannot be
          cancelled.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary">Yes</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={workInProgress}
        onHide={() => setWorkInProgress(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark this PO as Work in Progress(WIP)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark this Purchase Order as Work In Progress
          ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setWorkInProgress(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForWIP()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadDetailedReport}
        onHide={() => setDownloadDetailedReport(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Detailed Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This report contains the spreadsheet view of the table provided on
          this page.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDownloadDetailedReport(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => downloadDetailedReportAsSpreadsheet()}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadPendencyPDF}
        onHide={() => setDownloadPendencyPDF(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Pendency PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This PDF holds the Pending Quantity of each sku that is yet to be
          dispatched.
          {currentPurchaseOrder && (
            <PDFDownloadLink
              document={
                <CompanyPurchaseOrderPendingDispatchPDF
                  currentPurchaseOrder={currentPurchaseOrder}
                  chunkedPurchaseOrderListingItem={
                    chunkedPurchaseOrderListingItem
                  }
                  createdBy={userDetailsFromStore.user_details.userId}
                  totalSkus={currentPurchaseOrderListingDetails.length}
                  totalPendingQuantity={currentPurchaseOrderListingDetails.reduce(
                    (total, cp) => total + cp.demand,
                    0
                  )}
                  chunkSize={30}
                />
              }
            >
              {({ blob, url, loading, error }) => (
                <Button className="vendor-action-button" disabled={loading}>
                  {loading ? "Loading document..." : "Download Invoice PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CompanyPurchaseOrdersDetails;
