import React, { useState, useEffect } from "react";
import "./CSS/CatalogueEmbeddedListingsView.css";
import { Listing } from "../../atomic_components/types";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

import { toast } from "react-toastify";
import _ from "lodash";
import { useAppSelector } from "../../state/hooks";
import { AxiosError } from "axios";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";

interface ListingImageProps {
  images: string[]; // Define the type of the images prop as an array of strings
}

const ListingImage: React.FC<ListingImageProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // const handleImageError = () => {
  //   // Move to the next image if the current one fails to load
  //   setCurrentImageIndex((prevIndex) => prevIndex + 1);
  // };

  useEffect(() => {
    // Find the index of the first non-null and non-empty image
    for (let i = 0; i < images.length; i++) {
      if (images[i] && images[i].trim() !== "") {
        console.log(i);
        setCurrentImageIndex(i);
        return;
      }
    }
    // If no valid image is found, set index to null
    setCurrentImageIndex(images.length + 1);
  }, [images]);

  const handleImageError = () => {
    setCurrentImageIndex((prevIndex) => {
      let nextIndex = prevIndex + 1;
      // Check if the next index is out of bounds or if the next image is null or empty
      while (
        nextIndex < images.length &&
        (images[nextIndex] == null || images[nextIndex].trim() === "")
      ) {
        nextIndex++;
      }
      // console.log("Printing nextIndex from imageerror function ", nextIndex);
      return nextIndex;
    });
  };

  return (
    <div className="listings-img-container">
      {currentImageIndex > 4 ? (
        <img
          src="https://tech.intellozene.com/Media/na.png"
          alt="Product Image"
          onError={handleImageError}
        />
      ) : (
        <img
          src={images[currentImageIndex]}
          alt="Product Image"
          onError={handleImageError}
        />
      )}
    </div>
  );
};

const ListingCard: React.FC<{
  listing: Listing;
  handleCardClick: (listing: Listing) => void;
}> = ({ listing, handleCardClick }) => (
  <div
    className={`listings-view-card vendor-listing-card ${
      listing.isSelected ? "blue" : "white"
    }`}
    onClick={() => handleCardClick(listing)}
  >
    {/* <div className="listings-img-container">
      <img src={listing.img_wdim} alt="Product Image" />
    </div> */}
    <ListingImage
      images={[
        listing.img_wdim,
        listing.img_hd,
        listing.img_white,
        listing.img_link1,
        listing.img_link2,
      ]}
    />
    <div className="listings-view-card-title">
      <h3
        className={`sku-id-title ${
          listing.sku_type == "MULTI"
            ? ""
            : listing.available_quantity === 0
            ? "not-available"
            : "available"
        }`}
      >
        {listing.sku_id}
      </h3>
    </div>
    <p>OPS Tag: {listing.ops_tag}</p>
    {listing.sku_type == "MULTI" ? (
      <p className="purchase-order-vendor-listing-card-text">MULTI</p>
    ) : (
      <p className="purchase-order-vendor-listing-card-text">
        Available Quantity: {listing.available_quantity}
      </p>
    )}

    <p className="purchase-order-vendor-listing-card-text">
      Transfer Price : {listing.bulk_price}
    </p>
  </div>
);

type ListingsViewProps = {
  listings: Listing[];
  onCardClick: (selectedListing: Listing) => void;
};

export default function CatalogueEmbeddedLisingsView({
  listings,
  onCardClick,
}: ListingsViewProps) {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const handleCardClick = async (listing: Listing) => {
    onCardClick(listing);
  };

  return (
    <div className="catalogue-listings-container-main">
      {listings && listings.length > 0 && (
        <div className="vendor-listings-card-container">
          {listings.map((listing, index) => (
            <ListingCard
              key={index}
              listing={listing}
              handleCardClick={handleCardClick}
            />
          ))}
        </div>
      )}

      {listings && listings.length == 0 && (
        <div className="no-items-view">Nothing to Display</div>
      )}
    </div>
  );
}
