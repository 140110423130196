import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/SecondayListingsAll.css";
import { Button, Spinner, Table } from "react-bootstrap";

import {
  IncomingPurchaseOrder,
  PackComboListings,
  PacksAndCombos,
  SearchParams,
  SecondaryListingDetailsObject,
  SecondaryListings,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _, { update } from "lodash";
import { toast } from "react-toastify";

import { useAppSelector } from "../../state/hooks";
import InventoryNetworkUtils from "./NetworkUtils/InventoryNetworkUtils";
import SecondaryListingsAllSearchBar from "./SecondaryListingsAllSearchBar";

interface RouteParams {
  po_id: string;
}

function SecondaryListingsPackAndCombos() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const inventoryNetworkUtils = new InventoryNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [areListingsLoading, setAreListingsLoading] = useState<boolean>(false);
  const [currentPackComboListings, setCurrentPackComboListings] = useState<
    PackComboListings[]
  >([]);
  const [
    selectedPacksCombosListingDetailsObject,
    setSelectedPacksCombosListingDetailsObject,
  ] = useState<PacksAndCombos[]>();

  const [
    isPackComboListingDetailsLoading,
    setIsPackComboListingDetailsLoading,
  ] = useState<boolean>(false);

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
      setIsPackComboListingDetailsLoading(true);
      getPackComboListingDetails(
        currentPackComboListings[index].pack_combo_sku_id
      );
    }
  };

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const handleSecondaryListingsImageError = (
    imageKey: string,
    index: number
  ) => {
    if (!selectedPacksCombosListingDetailsObject) return;
    const updatedListing = [...selectedPacksCombosListingDetailsObject];

    if (imageKey == "img_wdim")
      updatedListing[index].listing.img_wdim =
        "http://tech.intellozene.com/Media/na.png";
    else if (imageKey == "img_link1")
      updatedListing[index].listing.img_link1 =
        "http://tech.intellozene.com/Media/na.png";
    else if (imageKey == "img_link2")
      updatedListing[index].listing.img_link2 =
        "http://tech.intellozene.com/Media/na.png";
    else if (imageKey == "img_hd")
      updatedListing[index].listing.img_hd =
        "http://tech.intellozene.com/Media/na.png";
    else if (imageKey == "img_white")
      updatedListing[index].listing.img_white =
        "http://tech.intellozene.com/Media/na.png";

    setSelectedPacksCombosListingDetailsObject(updatedListing);
  };

  const getEffectiveQuantity = () => {
    let effectiveQuantity = -1;
    if (selectedPacksCombosListingDetailsObject) {
      selectedPacksCombosListingDetailsObject.forEach((item, ind) => {
        if (effectiveQuantity == -1) {
          effectiveQuantity = Math.floor(
            item.listing.available_quantity / item.sku_count
          );
        } else {
          effectiveQuantity = Math.min(
            effectiveQuantity,
            Math.floor(item.listing.available_quantity / item.sku_count)
          );
        }
      });
    }

    return effectiveQuantity == -1 ? 0 : effectiveQuantity;
  };

  useEffect(() => {
    const fetchPacksAndCombosListing = async () => {
      try {
        const response =
          await inventoryNetworkUtils.getPacksAndCombosBySearchKeywordPaginated(
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentPackComboListings(response.data["content"]);
        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find secondary listings.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPacksAndCombosListing();
  }, []);

  const getPacksAndCombosBySearchKeywordPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await inventoryNetworkUtils.getPacksAndCombosBySearchKeywordPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentPackComboListings((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setAreListingsLoading(false);
    } catch (error) {
      toast.error("Error! Could not find secondary listings.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const getPackComboListingDetails = async (pack_combo_sku: string) => {
    try {
      const response =
        await inventoryNetworkUtils.getPacksAndCombosListingDetails(
          pack_combo_sku
        );
      setSelectedPacksCombosListingDetailsObject(response.data);
      setIsPackComboListingDetailsLoading(false);
    } catch (error) {
      toast.error("Error! Could not find secondary listing details object.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setIsPackComboListingDetailsLoading(false);
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getPacksAndCombosBySearchKeywordPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const handleSearch = (query: string) => {
    setCurrentPackComboListings([]);
    // setShowLoadingPage(true);
    setExpandedRow(null); // collapse if already expanded
    setAreListingsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getPacksAndCombosBySearchKeywordPaginated(query, 1, true);
  };

  return (
    <div className="upload-purchase-order-details-container">
      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentPackComboListings && (
          <div className="secondary-listings-container-main">
            <div className="secondary-listings-search-bar-container">
              <SecondaryListingsAllSearchBar onSearch={handleSearch} />
            </div>

            {areListingsLoading ? (
              <div className="loading-page">
                <Spinner animation="grow" />
                Loading, please wait...
              </div>
            ) : (
              <div className="secondary-listing-items-container">
                <Table bordered hover size="sm">
                  <thead className="sticky-header">
                    <tr>
                      <th>sr_no.</th>
                      <th>pack_combo_sku</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPackComboListings.map((packComboItem, index) => {
                      const isExpanded = expandedRow === index;
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={
                              isExpanded
                                ? "incoming-po-search-bar-row expanded-main-row"
                                : "incoming-po-search-bar-row"
                            }
                            onClick={() => toggleRow(index)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{index + 1}</td>
                            <td>{packComboItem.pack_combo_sku_id}</td>
                          </tr>
                          {isExpanded && (
                            <tr
                              className={`incoming-po-search-bar-row expandable-content-po ${
                                isExpanded ? "expanded" : "collapsed"
                              }`}
                            >
                              <td></td>
                              <td colSpan={10} className="po-expanded-cell">
                                {isPackComboListingDetailsLoading ? (
                                  <div className="loading-item">
                                    <Spinner animation="grow" />
                                    Loading, please wait...
                                  </div>
                                ) : (
                                  <div className="expanded-content">
                                    {selectedPacksCombosListingDetailsObject &&
                                      selectedPacksCombosListingDetailsObject.length >
                                        0 && (
                                        <div>
                                          <div className="secondary-listing-section-heading mb-4">
                                            Effective available quantity :{" "}
                                            {getEffectiveQuantity()}
                                          </div>

                                          {selectedPacksCombosListingDetailsObject.map(
                                            (singleItem, ind) => {
                                              return (
                                                <div className="secondary-listing-section mb-4">
                                                  <div className="secondary-listing-section-heading mb-2">
                                                    {" "}
                                                    {
                                                      singleItem.inventory_sku_id
                                                    }
                                                  </div>
                                                  <div className="image-list-secondary-listing">
                                                    {[
                                                      "img_hd",
                                                      "img_white",
                                                      "img_wdim",
                                                      "img_link1",
                                                      "img_link2",
                                                    ].map(
                                                      (imageKey: string) => (
                                                        <div
                                                          className="image-placeholder-add-item"
                                                          key={imageKey}
                                                        >
                                                          <img
                                                            src={
                                                              singleItem
                                                                .listing[
                                                                imageKey
                                                              ]
                                                            }
                                                            onError={() =>
                                                              handleSecondaryListingsImageError(
                                                                imageKey,
                                                                ind
                                                              )
                                                            }
                                                            alt={imageKey}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                  <div className="pack-and-combos-row mt-2 mb-3">
                                                    <div className="">
                                                      <div className="packs-info-available-quantity">
                                                        <label>
                                                          Available Quantity :{" "}
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name="description"
                                                          value={
                                                            singleItem.listing
                                                              .available_quantity
                                                          }
                                                          disabled={true}
                                                        />
                                                      </div>
                                                      <div className="packs-info-unit-contribution">
                                                        <label>
                                                          Units Contributed :{" "}
                                                        </label>
                                                        <input
                                                          type="text"
                                                          name="sku_count"
                                                          value={
                                                            singleItem.sku_count
                                                          }
                                                          disabled={true}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                {searchParams.item_count > 0 &&
                  searchParams.item_count <
                    searchParams.expected_item_count && (
                    <div className="load-more-catalogues-button-container">
                      <Button
                        variant="outline-primary"
                        className="load-more-catalogues-button"
                        onClick={() => loadMore()}
                      >
                        Load More..
                      </Button>
                    </div>
                  )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default SecondaryListingsPackAndCombos;
