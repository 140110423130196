import React, { useEffect, useState } from "react";
import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import CompanyPurchaseOrdersAdd from "./CompanyPurchaseOrdersAdd";
import CompanyPurchaseOrdersAllPurchaseOrders from "./CompanyPurchaseOrdersAllPurchaseOrders";
import CompanyPurchaseOrdersDetails from "./CompanyPurchaseOrdersDetails";
import "./CSS/CompanyPurchaseOrderDetailHome.css";
import Header from "../Header";
import CompanyPurchaseOrdersPackTaskDetails from "./CompanyPurchaseOrdersPackTaskDetails";

interface RouteParams {
  operation: string; // Define the type for the parameter you're expecting
  po_id: string;
}

function CompanyPurchaseOrdersDetailsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  useEffect(() => {
    if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
      history.push(`/listings/all`);
      return;
    }
    dispatch(ActiveTab__Action("incomingPos"));
  }, []);

  const { operation, po_id } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(operation);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/incomingPos/info/${item}/${po_id}`);
  };

  useEffect(() => {
    setSelectedItem(operation);
  }, [operation]);

  return (
    <div className="overlay-purchase-order-details">
      <Header />
      <div>
        <div className="nav-container">
          <nav className="navbar-purchase-order-details">
            <ul className="navbar-menu">
              <li
                className={`navbar-item-purchase-order ${
                  selectedItem === "details" ? "active" : ""
                }`}
                onClick={() => handleItemClick("details")}
              >
                PO Details
              </li>

              <li
                className={`navbar-item-purchase-order ${
                  selectedItem === "packTaskDetails" ? "active" : ""
                }`}
                onClick={() => handleItemClick("packTaskDetails")}
              >
                Pack Task Details
              </li>

              <li
                className={`navbar-item-purchase-order ${
                  selectedItem === "dispatchTaskDetails" ? "active" : ""
                }`}
                onClick={() => handleItemClick("dispatchTaskDetails")}
              >
                Dispatch Task Details
              </li>
            </ul>
          </nav>
        </div>

        {selectedItem === "details" && <CompanyPurchaseOrdersDetails />}
        {selectedItem === "packTaskDetails" && (
          <CompanyPurchaseOrdersPackTaskDetails />
        )}
      </div>
    </div>
  );
}

export default CompanyPurchaseOrdersDetailsHome;
