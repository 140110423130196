import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/SecondayListingsAll.css";
import { Button, Spinner, Table } from "react-bootstrap";

import {
  AisListing,
  IncomingPurchaseOrder,
  SearchParams,
  SecondaryListingDetailsObject,
  SecondaryListings,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _, { update } from "lodash";
import { toast } from "react-toastify";

import { useAppSelector } from "../../state/hooks";
import InventoryNetworkUtils from "./NetworkUtils/InventoryNetworkUtils";

import SecondaryListingsAisSearchbar from "./SecondaryListingsAisSearchbar";

interface RouteParams {
  po_id: string;
}

function SecondaryListingsAIS() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const inventoryNetworkUtils = new InventoryNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [areListingsLoading, setAreListingsLoading] = useState<boolean>(false);
  const [currentAisListings, setCurrentAisListings] = useState<AisListing[]>(
    []
  );

  const [
    isSecondaryListingDetailsLoading,
    setIsSecondaryListingDetailsLoading,
  ] = useState<boolean>(false);

  const onRowClicked = (index: number) => {};

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  useEffect(() => {
    const fetchAisListings = async () => {
      try {
        const response =
          await inventoryNetworkUtils.getAisListingsBySearchKeywordPaginated(
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentAisListings(response.data["content"]);
        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find ais listings.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchAisListings();
  }, []);

  const getAisListingsBySearchKeywordPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await inventoryNetworkUtils.getAisListingsBySearchKeywordPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentAisListings((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setAreListingsLoading(false);
    } catch (error) {
      toast.error("Error! Could not find ais listings.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getAisListingsBySearchKeywordPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const handleSearch = (query: string) => {
    setCurrentAisListings([]);
    // setShowLoadingPage(true);
    setExpandedRow(null); // collapse if already expanded
    setAreListingsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getAisListingsBySearchKeywordPaginated(query, 1, true);
  };

  return (
    <div className="upload-purchase-order-details-container">
      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentAisListings && (
          <div className="secondary-listings-container-main">
            <div className="secondary-listings-search-bar-container">
              <SecondaryListingsAisSearchbar onSearch={handleSearch} />
            </div>

            {areListingsLoading ? (
              <div className="loading-page">
                <Spinner animation="grow" />
                Loading, please wait...
              </div>
            ) : (
              <div className="secondary-listing-items-container">
                <Table bordered hover size="sm">
                  <thead className="sticky-header">
                    <tr>
                      <th>sr_no.</th>
                      <th>inventory_sku</th>
                      <th>ais_quantity</th>
                      <th>available_quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAisListings.map((aisListingItem, index) => {
                      const isExpanded = expandedRow === index;
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={"incoming-po-search-bar-row"}
                            onClick={() => onRowClicked(index)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{index + 1}</td>
                            <td>{aisListingItem.inventory_sku_id}</td>
                            <td>{aisListingItem.ais_quantity}</td>
                            <td>{aisListingItem.available_quantity}</td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
                {searchParams.item_count > 0 &&
                  searchParams.item_count <
                    searchParams.expected_item_count && (
                    <div className="load-more-catalogues-button-container">
                      <Button
                        variant="outline-primary"
                        className="load-more-catalogues-button"
                        onClick={() => loadMore()}
                      >
                        Load More..
                      </Button>
                    </div>
                  )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default SecondaryListingsAIS;
