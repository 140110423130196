import React, { useState, useLayoutEffect, useEffect } from "react";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import LoginPage from "./page components/LoginPage";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  SideBarOption__Action,
  WindowSmall__Action,
} from "./state/action-creators";

import ListingsHome from "./page components/ListingsAndInventory/ListingsHome";
import ProtectedRoute from "./routers/ProtectedRoute";
import HomePage from "./routers/HomePage";
import FormsHome from "./page components/FormsNew/FormsHome";
import FormsCategory from "./page components/FormsNew/FormsCategory";
import FormsPage from "./page components/FormsNew/FormsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListingDetails from "./page components/ListingsAndInventory/ListingDetails";
import VendorsHome from "./page components/Vendors/VendorsHome";
import VendorDetails from "./page components/Vendors/VendorDetails";
import PurchaseOrdersHome from "./page components/PurchaseOrders/PurchaseOrdersHome";
import PurchaseOrdersAddItem from "./page components/PurchaseOrders/PurchaseOrdersAddItem";
import PurchaseOrderPublished from "./page components/PurchaseOrders/PurchaseOrderPublished";
import CataloguesHome from "./page components/Catalogues/CataloguesHome";
import CatalogueDetailsPage from "./page components/Catalogues/CatalogueDetailsPage";
import CatalogueAddItems from "./page components/Catalogues/CatalogueAddItems";
import CompanyPurchaseOrdersHome from "./page components/CompanyPurchaseOrders/CompanyPurchaseOrdersHome";
import CompanyPurchaseOrdersAddItems from "./page components/CompanyPurchaseOrders/CompanyPurchaseOrdersAddItems";
import CompanyPurchaseOrdersDetails from "./page components/CompanyPurchaseOrders/CompanyPurchaseOrdersDetails";
import CompanyPurchaseOrdersDetailsHome from "./page components/CompanyPurchaseOrders/CompanyPurchaseOrdersDetailsHome";
import InventoryHome from "./page components/Inventory/InventoryHome";

function useWindowSize() {
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    function updateSize() {
      // dispatch the value on the basis of the size of the browser.
      if (window.innerWidth < 1300) dispatch(WindowSmall__Action(true));
      else dispatch(WindowSmall__Action(false));
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
}

function App() {
  const window_small = useAppSelector((state) => state.window_size);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("token"); // Replace with your token storage method
    const isTokenValid = true; //checkTokenValidity(token); // Implement token validity check
    setIsAuthenticated(isTokenValid);
  }, []);

  useWindowSize();
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route exact path="/">
            <Redirect to="/listings/top" />
          </Route>
          <Route exact path="/listings">
            <Redirect to="/listings/top" />
          </Route>
          <HomePage>
            <ProtectedRoute
              path="/listings/:category/:sku"
              component={ListingDetails}
              isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
              path="/listings/:category"
              component={ListingsHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/forms"
              exact
              component={FormsHome}
              isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
              path="/forms/:category/:subcategory"
              component={FormsPage}
              isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
              exact
              path="/forms/:category"
              component={FormsCategory}
              isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
              path="/vendors/:category/:vendor_id"
              component={VendorDetails}
              isAuthenticated={isAuthenticated}
            />
            <ProtectedRoute
              path="/vendors/:category"
              exact
              component={VendorsHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/vendor-purchase-orders/published/:po_id"
              exact
              component={PurchaseOrderPublished}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/vendor-purchase-orders/:category"
              exact
              component={PurchaseOrdersHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/vendor-purchase-orders/:category/:sub_category/:po_id"
              exact
              component={PurchaseOrdersAddItem}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/catalogues/all/details/:catalogue_id"
              exact
              component={CatalogueDetailsPage}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/catalogues/all/addItems/:catalogue_id"
              exact
              component={CatalogueAddItems}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/catalogues/:category"
              exact
              component={CataloguesHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/incomingPos/info/:operation/:po_id"
              exact
              component={CompanyPurchaseOrdersDetailsHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/incomingPos/:category"
              exact
              component={CompanyPurchaseOrdersHome}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/incomingPos/addPo/addPoDetails/:po_id"
              exact
              component={CompanyPurchaseOrdersAddItems}
              isAuthenticated={isAuthenticated}
            />

            <ProtectedRoute
              path="/inventory/secondaryListings/:category"
              exact
              component={InventoryHome}
              isAuthenticated={isAuthenticated}
            />
          </HomePage>
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
