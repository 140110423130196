import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/SecondayListingsAll.css";
import { Button, Spinner, Table } from "react-bootstrap";

import {
  IncomingPurchaseOrder,
  SearchParams,
  SecondaryListingDetailsObject,
  SecondaryListings,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _, { update } from "lodash";
import { toast } from "react-toastify";

import { useAppSelector } from "../../state/hooks";
import InventoryNetworkUtils from "./NetworkUtils/InventoryNetworkUtils";
import SecondaryListingsAllSearchBar from "./SecondaryListingsAllSearchBar";

interface RouteParams {
  po_id: string;
}

function SecondaryListingsAll() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const inventoryNetworkUtils = new InventoryNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [areListingsLoading, setAreListingsLoading] = useState<boolean>(false);
  const [currentSecondaryListings, setCurrentSecondaryListings] = useState<
    SecondaryListings[]
  >([]);
  const [
    selectedSecondaryListingDetailsObject,
    setSelectedSecondaryListingDetailsObject,
  ] = useState<SecondaryListingDetailsObject>();

  const [
    isSecondaryListingDetailsLoading,
    setIsSecondaryListingDetailsLoading,
  ] = useState<boolean>(false);

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
      setIsSecondaryListingDetailsLoading(true);
      getSecondaryListingsDetails(currentSecondaryListings[index]);
    }
  };

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const handleSecondaryListingsImageError = (imageKey: string) => {
    if (!selectedSecondaryListingDetailsObject) return;
    const updatedListing = { ...selectedSecondaryListingDetailsObject };

    if (updatedListing.secondary_sku_listing) {
      if (imageKey == "img_wdim")
        updatedListing.secondary_sku_listing.img_wdim =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link1")
        updatedListing.secondary_sku_listing.img_link1 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link2")
        updatedListing.secondary_sku_listing.img_link2 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.secondary_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.secondary_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_white")
        updatedListing.secondary_sku_listing.img_white =
          "http://tech.intellozene.com/Media/na.png";
    }

    setSelectedSecondaryListingDetailsObject(updatedListing);
  };

  const handleMasterListingsImageError = (imageKey: string) => {
    if (!selectedSecondaryListingDetailsObject) return;
    const updatedListing = { ...selectedSecondaryListingDetailsObject };

    if (updatedListing.master_sku_listing) {
      if (imageKey == "img_wdim")
        updatedListing.master_sku_listing.img_wdim =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link1")
        updatedListing.master_sku_listing.img_link1 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link2")
        updatedListing.master_sku_listing.img_link2 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.master_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.master_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_white")
        updatedListing.master_sku_listing.img_white =
          "http://tech.intellozene.com/Media/na.png";
    }

    setSelectedSecondaryListingDetailsObject(updatedListing);
  };

  const handleInventoryListingsImageError = (imageKey: string) => {
    if (!selectedSecondaryListingDetailsObject) return;
    const updatedListing = { ...selectedSecondaryListingDetailsObject };

    if (updatedListing.inventory_sku_listing) {
      if (imageKey == "img_wdim")
        updatedListing.inventory_sku_listing.img_wdim =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link1")
        updatedListing.inventory_sku_listing.img_link1 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link2")
        updatedListing.inventory_sku_listing.img_link2 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.inventory_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.inventory_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_white")
        updatedListing.inventory_sku_listing.img_white =
          "http://tech.intellozene.com/Media/na.png";
    }

    setSelectedSecondaryListingDetailsObject(updatedListing);
  };

  const handlePacksCombosListingsImageError = (imageKey: string) => {
    if (!selectedSecondaryListingDetailsObject) return;
    const updatedListing = { ...selectedSecondaryListingDetailsObject };

    if (updatedListing.pack_combo_sku_listing) {
      if (imageKey == "img_wdim")
        updatedListing.pack_combo_sku_listing.img_wdim =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link1")
        updatedListing.pack_combo_sku_listing.img_link1 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_link2")
        updatedListing.pack_combo_sku_listing.img_link2 =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.pack_combo_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_hd")
        updatedListing.pack_combo_sku_listing.img_hd =
          "http://tech.intellozene.com/Media/na.png";
      else if (imageKey == "img_white")
        updatedListing.pack_combo_sku_listing.img_white =
          "http://tech.intellozene.com/Media/na.png";
    }

    setSelectedSecondaryListingDetailsObject(updatedListing);
  };

  useEffect(() => {
    const fetchSecondaryListings = async () => {
      try {
        const response =
          await inventoryNetworkUtils.getSecondaryListingsBySearchKeywordPaginated(
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentSecondaryListings(response.data["content"]);
        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find secondary listings.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchSecondaryListings();
  }, []);

  const getSecondaryListingsBySearchKeywordPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await inventoryNetworkUtils.getSecondaryListingsBySearchKeywordPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentSecondaryListings((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
      setAreListingsLoading(false);
    } catch (error) {
      toast.error("Error! Could not find secondary listings.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const getSecondaryListingsDetails = async (
    secondaryListingObject: SecondaryListings
  ) => {
    try {
      const response = await inventoryNetworkUtils.getSecondaryListingDetails(
        secondaryListingObject
      );
      setSelectedSecondaryListingDetailsObject(response.data);
      setIsSecondaryListingDetailsLoading(false);
    } catch (error) {
      toast.error("Error! Could not find secondary listing details object.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setIsSecondaryListingDetailsLoading(false);
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getSecondaryListingsBySearchKeywordPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const handleSearch = (query: string) => {
    setCurrentSecondaryListings([]);
    // setShowLoadingPage(true);
    setExpandedRow(null); // collapse if already expanded
    setAreListingsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getSecondaryListingsBySearchKeywordPaginated(query, 1, true);
  };

  return (
    <div className="upload-purchase-order-details-container">
      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentSecondaryListings && (
          <div className="secondary-listings-container-main">
            <div className="secondary-listings-search-bar-container">
              <SecondaryListingsAllSearchBar onSearch={handleSearch} />
            </div>

            {areListingsLoading ? (
              <div className="loading-page">
                <Spinner animation="grow" />
                Loading, please wait...
              </div>
            ) : (
              <div className="secondary-listing-items-container">
                <Table bordered hover size="sm">
                  <thead className="sticky-header">
                    <tr>
                      <th>sr_no.</th>
                      <th>secondary_sku</th>
                      <th>master_sku</th>
                      <th>inventory_sku</th>
                      <th>pack_combo_sku</th>
                      <th>ais_quantity</th>
                      <th>effective_available_quantity</th>
                      <th>inventory_bypass_status</th>
                      <th>sku_type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSecondaryListings.map(
                      (secondaryListingItem, index) => {
                        const isExpanded = expandedRow === index;
                        return (
                          <React.Fragment key={index}>
                            <tr
                              className={
                                isExpanded
                                  ? "incoming-po-search-bar-row expanded-main-row"
                                  : "incoming-po-search-bar-row"
                              }
                              onClick={() => toggleRow(index)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td>{secondaryListingItem.secondary_sku}</td>
                              <td>{secondaryListingItem.master_sku}</td>
                              <td>{secondaryListingItem.inventory_sku_id}</td>
                              <td>{secondaryListingItem.pack_combo_sku_id}</td>
                              <td>{secondaryListingItem.ais_quantity}</td>
                              <td>{secondaryListingItem.available_quantity}</td>
                              <td>
                                {secondaryListingItem.inventory_bypass_status}
                              </td>
                              <td>{secondaryListingItem.sku_type}</td>
                            </tr>
                            {isExpanded && (
                              <tr
                                className={`incoming-po-search-bar-row expandable-content-po ${
                                  isExpanded ? "expanded" : "collapsed"
                                }`}
                              >
                                <td></td>
                                <td colSpan={10} className="po-expanded-cell">
                                  {isSecondaryListingDetailsLoading ? (
                                    <div className="loading-item">
                                      <Spinner animation="grow" />
                                      Loading, please wait...
                                    </div>
                                  ) : (
                                    <div className="expanded-content">
                                      {selectedSecondaryListingDetailsObject && (
                                        <div>
                                          {/* secondary_listings_object */}
                                          <div className="secondary-listing-section mb-4">
                                            <div className="secondary-listing-section-heading mb-2">
                                              {" "}
                                              Secondary SKU Section
                                            </div>
                                            {selectedSecondaryListingDetailsObject.secondary_sku_listing ? (
                                              <div className="image-list-secondary-listing">
                                                {[
                                                  "img_hd",
                                                  "img_white",
                                                  "img_wdim",
                                                  "img_link1",
                                                  "img_link2",
                                                ].map((imageKey: string) => (
                                                  <div
                                                    className="image-placeholder-add-item"
                                                    key={imageKey}
                                                  >
                                                    <img
                                                      src={
                                                        selectedSecondaryListingDetailsObject
                                                          .secondary_sku_listing[
                                                          imageKey
                                                        ]
                                                      }
                                                      onError={() =>
                                                        handleSecondaryListingsImageError(
                                                          imageKey
                                                        )
                                                      }
                                                      alt={imageKey}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            ) : (
                                              <div>
                                                {
                                                  secondaryListingItem.secondary_sku
                                                }{" "}
                                                is not part of APP listings.
                                              </div>
                                            )}
                                          </div>

                                          {/* master_sku_object */}
                                          <div className="secondary-listing-section mb-4">
                                            <div className="secondary-listing-section-heading mb-2">
                                              {" "}
                                              Master SKU Section
                                            </div>
                                            {selectedSecondaryListingDetailsObject.master_sku_listing ? (
                                              <div className="master-sku-details-section">
                                                <div className="image-list-secondary-listing">
                                                  {[
                                                    "img_hd",
                                                    "img_white",
                                                    "img_wdim",
                                                    "img_link1",
                                                    "img_link2",
                                                  ].map((imageKey: string) => (
                                                    <div
                                                      className="image-placeholder-add-item"
                                                      key={imageKey}
                                                    >
                                                      <img
                                                        src={
                                                          selectedSecondaryListingDetailsObject
                                                            .master_sku_listing[
                                                            imageKey
                                                          ]
                                                        }
                                                        onError={() =>
                                                          handleMasterListingsImageError(
                                                            imageKey
                                                          )
                                                        }
                                                        alt={imageKey}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                <div className="master-sku-inventory-bypass-status">
                                                  Inventory Bypass ON ? -{" "}
                                                  {
                                                    selectedSecondaryListingDetailsObject
                                                      .master_sku_listing
                                                      .inventory_bypass_on
                                                  }
                                                </div>

                                                {selectedSecondaryListingDetailsObject
                                                  .master_sku_listing
                                                  .inventory_bypass_on ==
                                                  "YES" && (
                                                  <div className="master-sku-inventory-bypass-status">
                                                    Available Bypass Inventory -{" "}
                                                    {
                                                      selectedSecondaryListingDetailsObject.master_sku_bypass_inventory
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div>
                                                Master Sku:{" "}
                                                {
                                                  secondaryListingItem.master_sku
                                                }{" "}
                                                : This SKU is not part of APP
                                                listings.
                                              </div>
                                            )}
                                          </div>

                                          {/* inventory_sku_object */}
                                          {secondaryListingItem.sku_type ==
                                            "SINGLE" && (
                                            <div className="secondary-listing-section mb-4">
                                              <div className="secondary-listing-section-heading mb-2">
                                                {" "}
                                                Inventory SKU Section
                                              </div>
                                              {selectedSecondaryListingDetailsObject.inventory_sku_listing ? (
                                                <div className="inventory-sku-details-section">
                                                  <div className="image-list-secondary-listing">
                                                    {[
                                                      "img_hd",
                                                      "img_white",
                                                      "img_wdim",
                                                      "img_link1",
                                                      "img_link2",
                                                    ].map(
                                                      (imageKey: string) => (
                                                        <div
                                                          className="image-placeholder-add-item"
                                                          key={imageKey}
                                                        >
                                                          <img
                                                            src={
                                                              selectedSecondaryListingDetailsObject
                                                                .inventory_sku_listing[
                                                                imageKey
                                                              ]
                                                            }
                                                            onError={() =>
                                                              handleInventoryListingsImageError(
                                                                imageKey
                                                              )
                                                            }
                                                            alt={imageKey}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                  <div className="inventory-sku-available-quantity">
                                                    Available Warehouse Quantity
                                                    -{" "}
                                                    {
                                                      selectedSecondaryListingDetailsObject.inventory_sku_available_quantity
                                                    }
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  {
                                                    secondaryListingItem.inventory_sku_id
                                                  }{" "}
                                                  is not part of APP listings.
                                                </div>
                                              )}
                                            </div>
                                          )}

                                          {/* pack_combo_sku_object */}
                                          {secondaryListingItem.sku_type ==
                                            "MULTI" && (
                                            <div className="secondary-listing-section mb-4">
                                              <div className="secondary-listing-section-heading mb-2">
                                                {" "}
                                                Pack & Combo SKU Section
                                              </div>
                                              {selectedSecondaryListingDetailsObject.pack_combo_sku_listing ? (
                                                <div className="image-list-secondary-listing">
                                                  {[
                                                    "img_hd",
                                                    "img_white",
                                                    "img_wdim",
                                                    "img_link1",
                                                    "img_link2",
                                                  ].map((imageKey: string) => (
                                                    <div
                                                      className="image-placeholder-add-item"
                                                      key={imageKey}
                                                    >
                                                      <img
                                                        src={
                                                          selectedSecondaryListingDetailsObject
                                                            .pack_combo_sku_listing[
                                                            imageKey
                                                          ]
                                                        }
                                                        onError={() =>
                                                          handlePacksCombosListingsImageError(
                                                            imageKey
                                                          )
                                                        }
                                                        alt={imageKey}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              ) : (
                                                <div>
                                                  Pack Combo Sku:{" "}
                                                  {
                                                    secondaryListingItem.pack_combo_sku_id
                                                  }{" "}
                                                  is not part of APP listings.
                                                </div>
                                              )}
                                            </div>
                                          )}

                                          {selectedSecondaryListingDetailsObject.pack_combo_childs &&
                                            secondaryListingItem.sku_type ==
                                              "MULTI" && (
                                              <div className="packs-and-combos-container mt-2">
                                                {selectedSecondaryListingDetailsObject.pack_combo_childs.map(
                                                  (packAndCombo, index) => {
                                                    return (
                                                      <div className="pack-and-combos-row mb-3">
                                                        <div className="image-placeholder-packs">
                                                          <img
                                                            src={
                                                              packAndCombo
                                                                .listing
                                                                .img_wdim
                                                            }
                                                            alt={`Image`}
                                                          />
                                                        </div>
                                                        <div className="packs-info-container">
                                                          <div className="packs-info-sku-id">
                                                            {
                                                              packAndCombo
                                                                .listing.sku_id
                                                            }
                                                          </div>
                                                          <div className="packs-info-available-quantity">
                                                            <label>
                                                              Available Quantity
                                                              :{" "}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              name="description"
                                                              value={
                                                                packAndCombo
                                                                  .listing
                                                                  .available_quantity
                                                              }
                                                              disabled={true}
                                                            />
                                                          </div>
                                                          <div className="packs-info-unit-contribution">
                                                            <label>
                                                              Units Contributed
                                                              :{" "}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              name="sku_count"
                                                              value={
                                                                packAndCombo.sku_count
                                                              }
                                                              disabled={true}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </tbody>
                </Table>
                {searchParams.item_count > 0 &&
                  searchParams.item_count <
                    searchParams.expected_item_count && (
                    <div className="load-more-catalogues-button-container">
                      <Button
                        variant="outline-primary"
                        className="load-more-catalogues-button"
                        onClick={() => loadMore()}
                      >
                        Load More..
                      </Button>
                    </div>
                  )}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default SecondaryListingsAll;
