import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { PurchaseOrder, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class CompanyPurchaseOrderNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getCompanyNames() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllCompanyNames}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from getCompanyNames");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllActiveCompanies() {
    try {
      let url = `${requestHeaders.rooturl}${requests.getAllCompanies}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from get all active companies");
      console.error("Error:", error);
      throw error;
    }
  }



  async addNewPurchaseOrder(body : any) {
    try {
      let url = `${requestHeaders.rooturl}${requests.addNewIncomingPurchaseOrder}`;
      const response = await this.api.post(url, body, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from createNewPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async geturchaseOrderByPOid(po_id : string) {
    try {
      let url = `${requestHeaders.rooturl}${requests.getIncomingPurchaseOrderById}${po_id}`;
      const response = await this.api.get(url, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from get purchase order by po id.");
      console.error("Error:", error);
      throw error;
    }
  }

  async submitPurchaseOrderData(jsonData : any[], po_id : string, userId : string){
    try {
      const queryParams: Record<string, any> = {
        created_by : userId, // New page value        
      };
      let url = `${requestHeaders.rooturl}${requests.addNewIncomingPurchaseOrderData}${po_id}`;
      const queryString = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
      url += `?${queryString}`;
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from submitPurchaseOrderData");
      console.error("Error:", error);
      throw error;
    }
  }

  async checkOpsAndMasterSkuMappingForPurchaseOrder(jsonData : any[]){
    try {   
      let url = `${requestHeaders.rooturl}${requests.checkOpsAndMasterSkuMappingForPurchaseOrder}`;      
      const response = await this.api.post(url, jsonData, {
        withCredentials: true,
      });
      return response;
    } catch (error) {
      // Handle errors or return a default value as needed
      console.log("Printing error from checkOpsAndMasterSkuMappingForPurchaseOrder");
      console.error("Error:", error);
      throw error;
    }
  }

  async getAllPurchaseOrdersPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
        }
    }
  async getAllWIPPurchaseOrdersPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number,
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        is_wip: "YES"
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchAllIncomingPurchaseOrdersPaginated}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllWIPPurchaseOrdersPaginated");
        console.error("Error:", error);
        throw error;
        }
    }


  async getAllPurchaseOrdersListingPaginated(
    po_number: string,
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchIncomingPurchaseOrdersListingPaginated}${po_number}?search_keyword=${searchKeyword}`;
        if (queryParams) {
            const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");
            url += `&${queryString}`;
        }
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getAllPurchaseOrdersListingPaginated");
        console.error("Error:", error);
        throw error;
        }
    }


  async getPurchaseOrderAnalyticsObject(
    po_number: string    
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.fetchPurchaseOrderAnalyticsObject}${po_number}`;        
        const response = await this.api.get(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getPurchaseOrderAnalyticsObject");
        console.error("Error:", error);
        throw error;
        }
    }

    async acknowledgePurchaseOrder(
      po_number: string ,   
      created_by: string
    ) {        
          try {
          let url = `${requestHeaders.rooturl}${requests.acknowledgePurchaseOrder}${po_number}?created_by=${created_by}`;        
          const response = await this.api.post(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from acknowledgePurchaseOrder");
          console.error("Error:", error);
          throw error;
          }
      }

    async markForWIP(
      po_number: string ,   
      created_by: string
    ) {        
          try {
          let url = `${requestHeaders.rooturl}${requests.markForWIP}${po_number}?created_by=${created_by}`;        
          const response = await this.api.post(url, { withCredentials: true });
          return response;
          } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from markForWIP");
          console.error("Error:", error);
          throw error;
          }
      }

  async createNewPackTask(
    po_number: string ,   
    created_by: string
  ) {        
        try {
        let url = `${requestHeaders.rooturl}${requests.createPackTask}${po_number}?created_by=${created_by}`;        
        const response = await this.api.post(url, { withCredentials: true });
        return response;
        } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from createNewPackTask");
        console.error("Error:", error);
        throw error;
        }
    }
    
}



export default CompanyPurchaseOrderNetworkUtils;
