import React, { useEffect, useState } from "react";

import "./CSS/InventoryHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import SecondaryListingsAll from "./SecondayListingsAll";
import Header from "../Header";
import InventoryAISListings from "./SecondaryListingsAIS";
import SecondaryListingsAIS from "./SecondaryListingsAIS";
import SecondaryListingsBulkOperations from "./SecondaryListingsBulkOperations";
import SecondaryListingsPackAndCombos from "./SecondaryListingsPackAndCombos";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ListingsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("inventory"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/inventory/secondaryListings/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div className="overlay-inventory-home">
      <Header />
      <div className="nav-container">
        <nav className="navbar-inventory-home">
          <ul className="navbar-menu">
            <li
              className={`navbar-item-inventory-home ${
                selectedItem === "all" ? "active" : ""
              }`}
              onClick={() => handleItemClick("all")}
            >
              All Secondary Listings
            </li>
            <li
              className={`navbar-item-inventory-home ${
                selectedItem === "packs-and-combos" ? "active" : ""
              }`}
              onClick={() => handleItemClick("packs-and-combos")}
            >
              Packs And Combos
            </li>
            <li
              className={`navbar-item-inventory-home ${
                selectedItem === "ais-listings" ? "active" : ""
              }`}
              onClick={() => handleItemClick("ais-listings")}
            >
              AIS (Always In Stock) Listings
            </li>
            <li
              className={`navbar-item-inventory-home ${
                selectedItem === "bulk-operations" ? "active" : ""
              }`}
              onClick={() => handleItemClick("bulk-operations")}
            >
              Bulk Operations
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "all" && <SecondaryListingsAll />}
      {selectedItem == "packs-and-combos" && <SecondaryListingsPackAndCombos />}
      {selectedItem == "ais-listings" && <SecondaryListingsAIS />}
      {selectedItem == "bulk-operations" && <SecondaryListingsBulkOperations />}
    </div>
  );
}

export default ListingsHome;
