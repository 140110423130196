import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/PurchaseOrdersOpen.css";
import PurchaseOrdersSearchBar from "./PurchaseOrdersSearchBar";
import PurchaseOrderNetworkUtils from "./NetworkUtils/PurchaseOrderNetworkUtils";
import {
  PurchaseOrder,
  PurchaseOrderItemWithLisiting,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { Prev } from "react-bootstrap/esm/PageItem";
import ColorCard from "./ColorCard";
import PurchaseOrdersListingSearchBar from "./PurchaseOrdersListingSearchBar";

function PurchaseOrdersListingView() {
  const history = useHistory();
  const location = useLocation();
  const purchaseOrdersNetworkUtil = new PurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(true);
  const [purchaseOrderItemsWithListing, setAllPurchaseOrderItemWithListing] =
    useState<PurchaseOrderItemWithLisiting[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const getPurcahseOrdersWithLotWiseListings = async (
    last_po_id: string,
    count: number
  ) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurcahseOrdersWithLotWiseListings(
          last_po_id,
          count.toString()
        );

      setAllPurchaseOrderItemWithListing((prevList) => [
        ...prevList,
        ...response.data,
      ]);

      if (response.data.length < 50) setShowLoadMoreButton(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching lotwise listings :", error);
      setIsLoading(false);
    }
  };

  const getPurcahseOrdersWithLotWiseListingsAndSearchKeyword = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await purchaseOrdersNetworkUtil.getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          50
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          // expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllPurchaseOrderItemWithListing((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      if (response.data["content"].length < 50) setShowLoadMoreButton(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching lotwise listings :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllPurchaseOrderItemWithListing([]);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    setIsLoading(true);

    if (query.length == 0 || query == "") {
      setShowLoadMoreButton(true);
      getPurcahseOrdersWithLotWiseListings("", 50);
    } else {
      getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(query, 1, true);
    }
  };

  const loadMore = () => {
    // console.log("FROM load more : ", searchParams.current_page_num);
    if (
      searchParams.search_string.length == 0 ||
      searchParams.search_string == ""
    ) {
      getPurcahseOrdersWithLotWiseListings(
        purchaseOrderItemsWithListing.length > 0
          ? purchaseOrderItemsWithListing[
              purchaseOrderItemsWithListing.length - 1
            ].po_id.toString()
          : "",
        50
      );
    } else {
      getPurcahseOrdersWithLotWiseListingsAndSearchKeyword(
        "",
        searchParams.current_page_num + 1,
        false
      );
      setSearchParams((prev) => {
        return {
          ...prev,
          current_page_num: prev.current_page_num + 1,
        };
      });
    }
  };

  useEffect(() => {
    getPurcahseOrdersWithLotWiseListings("", 50);
  }, []);

  const getPurchaseOrderStatus = (poStatus: string) => {
    if (poStatus == "PENDING") return "Pending";
    else if (poStatus == "PENDING_PUBLISHED") return "Published";
    else if (poStatus == "MARKED_MODIFICATION")
      return "Marked For Modification";
    else if (poStatus == "MARKED_RECEIVING") return "Marked for Receiving";
    else if (poStatus == "MARKED_PARTIALLY_RECEIVED")
      return "Partially Received";
    else if (poStatus == "MARKED_COMPLETED") return "Completed";
    else if (poStatus == "MARKED_CANCELLED") return "Cancelled";
    return "NA";
  };

  return (
    <div className="purchase-orders-container">
      <div className="purchase-orders-search-bar-container">
        <div className="colors-overview-container">
          {/* <ColorCard color="red" />
          <ColorCard color="orange" /> */}
          {/* <ColorCard color="green" /> */}
        </div>
        <PurchaseOrdersListingSearchBar onSearch={handleSearch} />
      </div>
      {purchaseOrderItemsWithListing.length > 0 ? (
        <div className="purchase-orders-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Image</th>
                <th>Sku Id</th>
                <th>Po Id</th>
                <th>PO status</th>
                <th>Vendor Id</th>
                <th>Required Quantity</th>
                <th>Expiry Date</th>
                <th>Date Created</th>
                <th>Created by</th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrderItemsWithListing.map((poItem, index) => {
                const dateStatus = DateTimeUtils.getDateStatus(
                  poItem.expected_date
                );
                return (
                  <tr className="purchase-orders-search-bar-row" key={index}>
                    <td>
                      <img
                        style={{ height: "50px", width: "50px" }}
                        src={poItem.listing.img_wdim}
                        alt="Product Image"
                      />
                    </td>
                    <td className="po-id-link">
                      <a href={`/listings/all/${poItem.sku_id}`}>
                        {poItem.sku_id}
                      </a>
                    </td>
                    <td className="po-id-link">
                      <a
                        href={
                          poItem.status == "PENDING" ||
                          poItem.status == "MARKED_MODIFICATION"
                            ? `/vendor-purchase-orders/add/addItems/${poItem.po_id}`
                            : `/vendor-purchase-orders/published/${poItem.po_id}`
                        }
                      >
                        {poItem.po_id}
                      </a>
                    </td>
                    <td>{getPurchaseOrderStatus(poItem.status)}</td>
                    <td className="po-id-link">
                      <a href={`/vendors/all/${poItem.vendor_id}`}>
                        {poItem.vendor_id}
                      </a>
                    </td>
                    <td>{poItem.required_quantity}</td>
                    <td
                      className={
                        `expiry-date ` +
                        (dateStatus < 0
                          ? "red"
                          : dateStatus <= 5
                          ? "orange"
                          : "green")
                      }
                    >
                      {DateTimeUtils.formatDateTime(poItem.expected_date)}
                    </td>
                    <td>{DateTimeUtils.formatDateTime(poItem.created_at)}</td>
                    <td>{poItem.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="load-more-purchase-orders-button-container">
            {showLoadMoreButton && (
              <Button
                variant="outline-primary"
                className="load-more-purchase-orders-button"
                onClick={() => loadMore()}
              >
                Load More..
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No Purchase Orders were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default PurchaseOrdersListingView;
