import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/VendorDetails.css";
import {
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import VendorsNetworkUtil from "./NetworkUtils/VendorsNetworkUtil";
import {
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";
import VendorListingsView from "./VendorListingsView";
import VendorsListingSearchBar from "./VendorsListingSearchBar";
import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";
import FilteredDropdown from "./FilteredDropdown";
import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
  vendor_id: string;
}

function VendorDetails() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );
  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const { category, vendor_id } = useParams<RouteParams>();
  const [vendorListings, setVendorListings] = useState<VendorListing[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [newItemCostPrice, setNewItemCostPrice] = useState<number>(0);
  const [newItemSkuId, setNewItemSkuId] = useState<string>("");
  const [allSkuIds, setAllSkuIds] = useState<string[]>([]);
  const [vendorDetailsEditMode, setVendorDetailsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorSpecialityEditMode, setVendorSpecialityEditMode] =
    useState(false);
  const [filteredVendorListings, setFilteredVendorListings] = useState<
    VendorListing[]
  >([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor>({
    id: 0,
    vendor_name: "",
    vendor_address_line: "",
    vendor_city: "",
    vendor_state: "",
    vendor_postal_code: "",
    vendor_gstin: "",
    vendor_contact_number: "",
    created_by: "",
    modified_by: "",
    created_at: "",
    updated_at: "",
    specialties: [],
  });

  const [localVendor, setLocalVendor] = useState<Vendor>(currentVendor);

  const [currentVendorSpecialities, setCurrentVendorSpecialities] = useState<
    Specialty[]
  >([]);
  const [localVendorSpecialities, setLocalVendorSpecialities] = useState<
    Specialty[]
  >([]);

  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const listingNetworkUtil = new ListingNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const handleShowAddItem = () => setShowAddNewItem(true);
  const handleCloseAddItem = () => {
    setShowAddNewItem(false);
  };

  useEffect(() => {
    const fetchVendorByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorByVendorId(
          vendor_id
        );
        console.log(response.data);
        setCurrentVendor(response.data);
        setLocalVendor(response.data);
        setCurrentVendorSpecialities(response.data["specialties"]);
        setLocalVendorSpecialities(response.data["specialties"]);
      } catch (error) {
        console.error("Error in fetching vendor by vendor_id :", error);
      }
    };

    const fetchVendorListingsByVendorId = async (vendor_id: string) => {
      try {
        const response = await vendorsNetworkUtil.getVendorListingsByVendorId(
          vendor_id
        );
        console.log(response.data);
        setVendorListings(response.data);
        setFilteredVendorListings(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
        setIsLoading(false);
      }
    };

    const fetchAllSkuIds = async () => {
      try {
        const response = await listingNetworkUtil.getAllSkuIds();
        setAllSkuIds(response.data);
      } catch (error) {
        console.error(
          "Error in fetching vendor listings by vendor id :",
          error
        );
      }
    };

    if (!userDetailsFromStore.authorities.includes("AUTH_ADMIN")) {
      history.push(`/listings/all`);
    }

    fetchVendorByVendorId(vendor_id);
    fetchVendorListingsByVendorId(vendor_id);
    fetchAllSkuIds();
  }, []);

  useEffect(() => {
    if (searchString.length == 0) {
      setFilteredVendorListings(_.cloneDeep(vendorListings));
    } else {
      setFilteredVendorListings(filterVendorsListings(searchString));
    }
  }, [vendorListings]);

  const handleSearch = (query: string) => {
    // dispatch(SearchText__Action(query));
    // search(query, 1);
    setSearchString(query);
    console.log("vendor listing searched ", query);
    if (query.length == 0)
      setFilteredVendorListings(_.cloneDeep(vendorListings));
    else setFilteredVendorListings(filterVendorsListings(query));
  };

  const filterVendorsListings = (query: string) => {
    query = query.toLowerCase();
    return _.cloneDeep(vendorListings).filter((vendorListing) => {
      const skuMatches = vendorListing.listing.sku_id
        .toLowerCase()
        .includes(query);

      const opsTagMatches = vendorListing.listing.ops_tag
        .toLowerCase()
        .includes(query);

      return skuMatches || opsTagMatches;
    });
  };

  const handleVendorDetailsEditClick = () => {
    setVendorDetailsEditMode(true);
  };

  const handleVendorDetailCancelClick = () => {
    setVendorDetailsEditMode(false);
    setLocalVendor(currentVendor);
  };

  const handleVendorSpecialityEditClick = () => {
    setVendorSpecialityEditMode(true);
  };

  const handleVendorSpecialityCancelClick = () => {
    setVendorSpecialityEditMode(false);
    setLocalVendorSpecialities(_.cloneDeep(currentVendorSpecialities));
  };

  const handleSpecialtyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    console.log("Inside this");
    const { name, value } = e.target;
    console.log(value);
    console.log(name);
    const updatedSpecialities = [...localVendorSpecialities];
    updatedSpecialities[index] = {
      ...updatedSpecialities[index],
      [name]: value,
      modified_by: userDetailsFromStore.user_details.userId,
    };

    console.log(updatedSpecialities);
    setLocalVendorSpecialities(updatedSpecialities);
  };

  const addNewSpeciality = () => {
    const newSpecialty: Specialty = {
      id: 0,
      vendor_id: currentVendor.id,
      vendor_speciality: "",
      created_by: userDetailsFromStore.user_details.userId,
      modified_by: userDetailsFromStore.user_details.userId,
      created_at: "", // Change this type to match your date format, e.g., Date or string
      updated_at: "", // Change this type to match your date format, e.g., Date or string
    };

    setLocalVendorSpecialities([...localVendorSpecialities, newSpecialty]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalVendor({
      ...localVendor,
      [name]: value,
      modified_by: userDetailsFromStore.user_details.userId,
    });
  };

  const saveVendorDetails = async () => {
    console.log("trying to save vendor details.");
    try {
      const response = await vendorsNetworkUtil.updateVendorDetailsByVendorId(
        vendor_id,
        localVendor
      );

      console.log(response);
      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setCurrentVendor(localVendor);
      setVendorDetailsEditMode(false);
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 422) {
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const saveSpecialtyChanges = async () => {
    console.log("trying to save vendor specialty changes.");
    try {
      const response = await vendorsNetworkUtil.updateVendorSpecialtyByVendorId(
        vendor_id,
        localVendorSpecialities
      );

      console.log(response);
      toast.success("Successfully updated!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setCurrentVendorSpecialities(response.data);
      setLocalVendorSpecialities(response.data);
      setVendorSpecialityEditMode(false);
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 422) {
        toast.error("Fields cannot be set empty!", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const handleSkuFromDropdownSelected = (value: string) => {
    // Handle the selected value in the parent component
    console.log("Selected Value:", value);
    setNewItemSkuId(value);
  };

  const handleNewItemCostPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewItemCostPrice(parseFloat(e.target.value));
  };

  const mapNewItem = async () => {
    if (newItemSkuId == null || newItemSkuId == "") {
      toast.error("Error, No SKU id selected. Select one and try again.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }
    if (newItemCostPrice == 0) {
      toast.error("Error, Cost Price should be greater than 0.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    try {
      const newItem: any = {
        cost_price: newItemCostPrice,
        modified_by: userDetailsFromStore.user_details.userId,
      };
      const response = await vendorsNetworkUtil.mapNewItemToVendor(
        newItem,
        vendor_id,
        newItemSkuId
      );

      console.log(response);
      toast.success("Successfully added!", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setVendorListings([...vendorListings, response.data]);
      setNewItemSkuId("");
      setNewItemCostPrice(0);
      handleCloseAddItem();
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      console.log(err.response && err.response.data);
      if (err.response && err.response.data && err.response.status == 400) {
        toast.error("Error, the sku you are trying to add is already mapped", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const onCostPriceChange = (
    currentListing: VendorListing,
    newCostPrice: number
  ) => {
    console.log(
      "new cost price recieved from child : ",
      currentListing,
      newCostPrice
    );

    setVendorListings((prevState) =>
      prevState.map((obj) =>
        obj.id === currentListing.id
          ? {
              ...obj,
              cost_price: newCostPrice,
              updated_at: DateTimeUtils.getCurrentTimeFormatted(),
            }
          : obj
      )
    );
  };

  const onRemoveListing = (toRemoveListing: VendorListing) => {
    const updatedList2 = vendorListings.filter(
      (obj) => obj.id !== toRemoveListing.id
    );
    setVendorListings(updatedList2);
  };

  const navigateToCreateNewPurchaseOrder = () => {
    //pass the parameters in here.d
    history.push(`/vendor-purchase-orders/add?vendorId=${localVendor.id}`);
    // window.open(`/purchase-orders/add/`, "_blank");
  };

  return (
    <div className="overlay">
      <div className="vendor-details">
        <Header />
        <div className="main-container">
          <div className="vendor-action-container">
            <div className="vendor-summary">
              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4 className="vendor_id">Vendor Details</h4>
                  <div className="vendor-details-button-container">
                    <button
                      type="button"
                      onClick={handleVendorDetailsEditClick}
                      disabled={vendorDetailsEditMode}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={handleVendorDetailCancelClick}
                      disabled={!vendorDetailsEditMode}
                    >
                      Cancel
                    </button>
                    {vendorDetailsEditMode && (
                      <button type="button" onClick={saveVendorDetails}>
                        Save
                      </button>
                    )}
                  </div>
                </div>
                <div className="grid-1-cells">
                  <div className="grid-item-details-card">
                    <label>Vendor Id</label>
                    <input
                      type="text"
                      name="vendor_id"
                      value={currentVendor.id}
                      disabled={true}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor Name</label>
                    <input
                      type="text"
                      name="vendor_name"
                      value={localVendor.vendor_name}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor Address Line</label>
                    <input
                      type="text"
                      name="vendor_address_line"
                      value={localVendor.vendor_address_line}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor City</label>
                    <input
                      type="text"
                      name="vendor_city"
                      value={localVendor.vendor_city}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor State</label>
                    <input
                      type="text"
                      name="vendor_state"
                      value={localVendor.vendor_state}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor Postal Code</label>
                    <input
                      type="text"
                      name="vendor_postal_code"
                      value={localVendor.vendor_postal_code}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor GSTIN</label>
                    <input
                      type="text"
                      name="vendor_gstin"
                      value={localVendor.vendor_gstin}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                  <div className="grid-item-details-card">
                    <label>Vendor Contact Number</label>
                    <input
                      type="text"
                      name="vendor_contact_number"
                      value={localVendor.vendor_contact_number}
                      onChange={handleChange}
                      disabled={!vendorDetailsEditMode}
                    />
                  </div>
                </div>
              </div>

              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4 className="vendor_id">Vendor Analysis</h4>
                </div>
                <div className="grid-2-cells">
                  <div className="grid-item-details-card">
                    <label>No. of items by Vendor</label>
                    <input
                      type="text"
                      name="vendor_item_count"
                      value={vendorListings.length}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4 className="vendor_id">Vendor actions</h4>
                </div>
                <div className="vendor-action-buttons-container">
                  <Button
                    variant="outline-primary"
                    className="vendor-action-button"
                    onClick={() => handleShowAddItem()}
                  >
                    Map new listing to this vendor
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="vendor-action-button"
                    onClick={() => navigateToCreateNewPurchaseOrder()}
                  >
                    Create New Purchase order
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="vendor-action-button"
                  >
                    See open Purchase orders
                  </Button>
                </div>
              </div>

              <div className="vendor-basics vendors-info-card">
                <div className="vendors-view-card-title">
                  <h4>Vendor Specialties</h4>
                  <div className="vendor-details-button-container">
                    <button
                      type="button"
                      onClick={handleVendorSpecialityEditClick}
                      disabled={vendorSpecialityEditMode}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={handleVendorSpecialityCancelClick}
                      disabled={!vendorSpecialityEditMode}
                    >
                      Cancel
                    </button>
                    {vendorSpecialityEditMode && (
                      <button type="button" onClick={saveSpecialtyChanges}>
                        Save
                      </button>
                    )}
                    {vendorSpecialityEditMode && (
                      <button type="button" onClick={addNewSpeciality}>
                        Add New
                      </button>
                    )}
                  </div>
                </div>
                <div className="grid-1-cells">
                  {localVendorSpecialities.map((sp, index) => (
                    <div className="grid-item-details-card" key={sp.id}>
                      <input
                        type="text"
                        name="vendor_speciality"
                        value={sp.vendor_speciality}
                        onChange={(e) => handleSpecialtyChange(e, index)}
                        disabled={!vendorSpecialityEditMode}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="vendor-skus-spinner-container">
              {" "}
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="vendor-skus-container">
              <div className="vendor-listings-search-bar-container">
                <VendorsListingSearchBar
                  onSearch={handleSearch}
                ></VendorsListingSearchBar>
              </div>

              <div className="listings-container vendor-listings-container">
                <VendorListingsView
                  localVendorListings={filteredVendorListings}
                  onCostPriceChange={onCostPriceChange}
                  onRemoveListing={onRemoveListing}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Offcanvas
        show={showAddNewItem}
        onHide={handleCloseAddItem}
        // backdrop="static"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <p>Map new item to this vendor</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="container">
            <FilteredDropdown
              options={allSkuIds}
              onItemSelected={handleSkuFromDropdownSelected}
            />
            <div className="grid-1-cells">
              <div className="grid-item-details-card">
                <label>Add Cost Price</label>
                <input
                  type="number"
                  name="new_item_cost_price"
                  value={newItemCostPrice.toString()}
                  onChange={(e) => handleNewItemCostPriceChange(e)}
                />
              </div>
            </div>

            <div className="offcanvas-button-container">
              <button type="button" onClick={mapNewItem}>
                Save
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default VendorDetails;
